$(window).on('load',function(){
    miniShop2.Callbacks.Cart.change.response.success = function(){ window.location.reload() }
    miniShop2.Callbacks.Cart.remove.response.success = function(){ window.location.reload() }
    
});



//������ ���� � ����� ��� input count
if (navigator.userAgent.match(/OS X.*Safari/)) {
   document.body.className += 'safari';
} 